<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-tabs
      lazy
      pills
      @activate-tab="handleActivateTab($event)"
    >
      <b-tab
        title="Estado económico"
        :active="lastOpenedTab === 0"
        class="pb-1"
      > 
        <JobEconomicAdvanceTable :loading="loading" />
        <JobEconomicStateTable />
        <JobEconomicPrevisionTable />
        <JobAdditionalLinesCard
          :job-id="jobId"
          @expected-cost-line-created="handleExpectedCostLineCreated"
        />
        <JobExpectedCostLinesCard
          ref="job-expected-cost-lines-card"
          :job-id="jobId"
        />
      </b-tab>
      <b-tab
        title="Pedido"
        :active="lastOpenedTab === 1"
      >
        <JobOrdersCard
          title="totales globales"
          :totals="jobOrders.totals"
          bg-class="bg-action-blue"
        />
        <JobOrdersCard
          v-for="({ count, total_gross_order, total_billing, total_pending_billing, lines, name}, index) in jobOrders.data"
          :key="index"
          :title="name"
          :totals="{ count, total_gross_order, total_billing, total_pending_billing }"
          collapse-enabled
        >
          <JobOrdersTable :orders="lines || []" />
        </JobOrdersCard>
      </b-tab>
      <b-tab
        title="Fra. Proveedores"
        :active="lastOpenedTab === 2"
      >
        <JobPurchaseInvoicesCard
          title="totales globales"
          :totals="jobPurchaseInvoicesTotals"
          bg-class="bg-action-blue"
        />
        <JobPurchaseInvoicesCard
          v-for="({ count, total_gross, total_invoice, total_retention, lines, name}, index) in jobPurchaseInvoices.data"
          :key="index"
          :title="name"
          :totals="{ count, total_gross, total_invoice, total_retention }"
          collapse-enabled
        >
          <JobPurchaseInvoicesTable :purchase-invoices="lines || []" />
        </JobPurchaseInvoicesCard>
      </b-tab>
      <b-tab
        v-if="!showMaintenanceTable"
        :disabled="!canAccessResource($data.$constants.RESOURCES.RESOURCE_CERTIFICATIONS, $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)"
        title="Certificaciones"
        :active="lastOpenedTab === 3"
      >
        <JobCertificationsCard :job-id="jobId" />
      </b-tab>
      <b-tab
        v-if="showMaintenanceTable"
        title="Certificaciones"
        :active="lastOpenedTab === 3"
      >
        <JobMaintenanceCertificationsCard :jobId="jobId" />
      </b-tab>
      <b-tab
        title="Fra. Emitidas"
        :active="lastOpenedTab === 4"
      >
        <JobSaleInvoicesCard :job-id="jobId" />
      </b-tab>
      <b-tab
        :disabled="!canAccessResource($data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES, $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)"
        title="No conformidades"
        :active="lastOpenedTab === 5"
      >
        <JobNonConformitiesCard :job-id="jobId" />
      </b-tab>
      <b-tab
        v-if="isMaintenance"
        title="Alcance oferta"
        :disabled="!contractSelected.contract"
        :active="lastOpenedTab === 6"
      >
        <RaterCalculationTable :jobId="jobId"/>
      </b-tab>
      <template #tabs-end>
        <b-button
          :disabled="job && job.division.reference_code === 'M'"
          class="custom-tab ml-1"
          variant="outline-light" 
          size="sm" 
          pill 
          @click="downloadAssists"
        >
        <span>
          Asistencias
        </span> 
      </b-button>
      </template>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import JobEconomicAdvanceTable from '@/components/jobs/table/JobEconomicAdvanceTable.vue'
import JobEconomicStateTable from '@/components/jobs/table/JobEconomicStateTable.vue'
import JobEconomicPrevisionTable from '@/components/jobs/table/JobEconomicPrevisionTable.vue'
import JobAdditionalLinesCard from '@/components/jobs/card/JobAdditionalLinesCard.vue'
import JobExpectedCostLinesCard from '@/components/jobs/card/JobExpectedCostLinesCard.vue'
import JobOrdersCard from '@/components/jobs/card/JobOrdersCard.vue'
import JobOrdersTable from '@/components/jobs/table/JobOrdersTable.vue'
import JobPurchaseInvoicesCard from '@/components/jobs/card/JobPurchaseInvoicesCard.vue'
import JobPurchaseInvoicesTable from '@/components/jobs/table/JobPurchaseInvoicesTable.vue'
import JobCertificationsCard from '@/components/jobs/card/JobCertificationsCard.vue'
import JobSaleInvoicesCard from '@/components/jobs/card/JobSaleInvoicesCard.vue'
import JobNonConformitiesCard from '@/components/jobs/card/JobNonConformitiesCard.vue'
import JobsApi from '@/api/jobs-api'
import RaterCalculationTable from '@/components/rater/table/RaterCalculationTable.vue'
import JobMaintenanceCertificationsCard from '@/components/jobs/card/JobMaintenanceCertificationsCard.vue'

export default {
  name: 'JobTabs',
  components: {
    RaterCalculationTable,
    JobNonConformitiesCard,
    JobSaleInvoicesCard,
    JobCertificationsCard,
    JobPurchaseInvoicesTable,
    JobPurchaseInvoicesCard,
    JobOrdersTable,
    JobOrdersCard,
    JobExpectedCostLinesCard,
    JobAdditionalLinesCard,
    JobEconomicPrevisionTable,
    JobEconomicStateTable,
    JobEconomicAdvanceTable,
    JobMaintenanceCertificationsCard
  },
  props: {
    jobId: {
      type: String,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('job', {
      jobOrders: 'getJobOrders',
      jobPurchaseInvoices: 'getJobPurchaseInvoices',
      isMaintenance: 'getIsMaintenance',
      isService: 'getIsService',
      lastOpenedTab: 'getLastOpenedTab',
    }),
    ...mapGetters('auth', {
      canAccessResource: 'canAccessResource',
    }),
    jobPurchaseInvoicesTotals() {
      if (this.jobPurchaseInvoices.totals instanceof Array) {
        return {}
      }

      return this.jobPurchaseInvoices.totals
    },
    contractSelected () {
      return this.jobId ? this.$store.getters['job/getContractSelected'](this.jobId) : null
    },
    showMaintenanceTable () {
      return this.isMaintenance || this.isService
    }
  },
  mounted() {
    this.getJobInfoData()
    this.loadLastOpenedTab()
  },
  methods: {
    ...mapActions('job', ['loadJobInfo', 'setLastOpenedTab', 'loadLastOpenedTab']),
    async getJobInfoData() {
      if (!this.jobId) {
        return
      }

      this.loading = true
      try {
        await this.loadJobInfo(this.jobId)
      } finally {
        this.loading = false
      }
    },
    handleActivateTab(newTabIndex) { 
      this.$emit('tab-activated', newTabIndex)
      this.setLastOpenedTab(newTabIndex)
    },
    async handleExpectedCostLineCreated(expectedCostLine) {
      this.$refs['job-expected-cost-lines-card'].focusExpectedCostLine(expectedCostLine)
    },
    async downloadAssists () {
      await JobsApi.downloadAssists(this.jobId)
    }
  },
}
</script>

<style scoped>
.custom-tab:hover {
  background-color: transparent;
  color: #5E5873; 
}

.custom-tab > span{
  font-weight: bold; 
  color: #5E5873; 
  font-size: 14px; 
  letter-spacing: normal;
}
</style>
